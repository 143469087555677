import image1 from '../../CommonComponents/images/Asset/Layer 1_3.png'  
import image2 from '../../CommonComponents/images/Asset/Layer 1_2.png'  
import image3 from '../../CommonComponents/images/Asset/Layer 2_2.png'  
export const AboutData = [
  {
    id: 1,
    title: " Reliable Resources",
    icon: image1,
    details: `We believe that business have the 
    power to change the world for the 
    better and are dedicated to providing
    the resources and support needed for sustainable and responsible growth. partner with us to drive positive 
    change in your business and the world. `,
  },
  {
    id: 2,
    title: "Solutions Focused",
    icon: image2,
    details: `We pride ourselves on providing effective solution-focused services. partner with us and experience the difference of working with a company that prioritizes finding the best solution for your business.`,
  },
  {
    id: 3,
    title: "Time Efficient",
    icon: image3,
    details: `We strive to provide efficient solutions that maximize results while minimizing wasted resources. partners with us to achieve your objectives and meet your deadlines.`,
  },
];
