import styled from "styled-components";

export const FooterWrapper = styled.div`
  height: 50vh;
  background: #ffffff;
  padding-left: 7%;
  @media (min-width: 300px) and (max-width: 1280px) {
    display: none;
  }
`;
export const FooterMobileWrapper = styled.div`
  display: none;

  @media (min-width: 300px) and (max-width: 1280px) {
    display: flex;
  }
`;
export const FooterImage = styled.img`
  width: 250px;
`;
export const FooterText = styled.p`
  width: 100%;
  text-align: left;
  max-width: 500px;

  font-family: "Calibre";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
`;
export const FooterHeaderTitles = styled.span`
  letter-spacing: 2px;
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */

  color: #050505;
`;
export const FooterHeaderText = styled.div`
  margin-top: 20px;
  font-family: "Calibre";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  color: #050505;
`;
export const Divider = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 94%;
  margin-top: 90px;

  border: 1px solid #6e6a6a;
`;
export const RightsText = styled.div`
  padding: 20px;

  font-family: "Calibre";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  color: #050505;
`;
