import image1 from '../../CommonComponents/images/Asset/project manager.png'  
import image2 from "../../CommonComponents/images/Asset/software dev.png";
import image3 from "../../CommonComponents/images/Asset/pen.png";
import image4 from "../../CommonComponents/images/Asset/customer .png";
import image5 from "../../CommonComponents/images/Asset/Layer 1.png";
import image6 from "../../CommonComponents/images/Asset/Plus.png";

export const TeamData = [
  { id: 1, image: image1, title: "Project Managers" },
  { id: 2, image: image2, title: "Software Developer" },
  { id: 3, image: image3, title: "Graphic Designers" },
  { id: 4, image: image4, title: "Customer Success" },
  { id: 5, image: image5, title: "Established Entrepreneurs" },
  { id: 6, image: image6, title: "And a lot more" },
];
