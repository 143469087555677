/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";

import Sheet from "@mui/joy/Sheet";
import { useRecoilState } from "recoil";
import { ScheduleModal } from "../../CommonComponents/GlobalStateManagement";
import { ScheduleFrame } from "./Home.style";

function Schedule() {
  const [isScheduleModalOpen, setIsScheduleModalOpen] =
    useRecoilState(ScheduleModal);

  const handleClose = () => {
    setIsScheduleModalOpen(false);
  };


  return (
    <div style={{height:'100%',overflow:'hidden'}}>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={isScheduleModalOpen}
        onClose={handleClose}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center"}}
      >
        <Sheet
      
          sx={{
            maxWidth: 1000,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.body",
            }}
          />

          <ScheduleFrame
            src="https://app.usemotion.com/meet/kdkadzashie/newclient?d=30"
            
         />
        </Sheet>
      </Modal>
    </div>
  );
}

export default Schedule;
