import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { AboutHeaderText } from "../About/About.style";
import { ServiceGrid, ServiceHeaderBox } from "./Serivce.style";
import { Business, CRM, HealthCare } from "./ServiceData";
import { Button } from "@mui/joy";
import ServiceMobile from "./ServiceMobile";

export interface ServiceProps {
  id: number;
  title: string;
  details: string;
  image: string;
  style: any;
}
function Service() {
  return (
    <div
      style={{ height: "100%", background: "#FFFFFF", width: "100%",paddingBottom:"80px" }}
      id="/service"
    >
      <AboutHeaderText>
        <ServiceHeaderBox> </ServiceHeaderBox> Featured Services
      </AboutHeaderText>

      <ServiceGrid>
        <Box sx={{ width: "100%", flexDirection: "column" }}>
          <Grid
            container
            rowSpacing={0}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {Business.map((item: ServiceProps) => (
              <Grid item xs={6} key={item.id}>
                <div style={{ marginLeft: "120px", }}>
                  <img
                    src={item.image}
                    width="600px"
                    height="auto"
                    alt="/"
                    style={{
                      justifyContent: "flex-start",
                      display: "flex",
                      minWidth: "100px",
                      width: "100%",
                      maxWidth: "600px",
                    }}
                  />
                  <h2
                    style={{
                      justifyContent: "flex-start",
                      display: "flex",
                      fontFamily: "Clash Display",fontSize:"30px"
                    }}
                  >
                    {item.title}
                  </h2>
                  <p
                    style={{
                      textAlign: "left",
                      fontFamily: "Calibre",
                      width: "600px",fontSize:"17px"
                    }}
                  >
                    {item.details}
                  </p>
                </div>
              </Grid>
            ))}
            {CRM.map((item: ServiceProps) => (
              <Grid item xs={6} key={item.id}>
                <div
                  style={{
                    marginLeft: "0px",
                    position: "relative",
                    paddingTop: "100px",
                  }}
                >
                  <img
                    src={item.image}
                    width="600px"
                    height="auto"
                    alt="/"
                    style={{
                      justifyContent: "flex-start",
                      display: "flex",
                      minWidth: "100px",
                      width: "100%",
                      maxWidth: "600px",
                    }}
                  />
                  <div>
                    <h2
                      style={{
                        justifyContent: "flex-start",
                        display: "flex",
                        fontFamily: "Clash Display",fontSize:"30px"
                      }}
                    >
                      {item.title}
                    </h2>
                    <p
                      style={{
                        textAlign: "left",
                        fontFamily: "Calibre",
                        width: "600px",
                        fontSize:"17px"
                      }}
                    >
                      {item.details}
                    </p>
                    <Button
                      variant="solid"
                      style={{
                        background: "#F3C539",
                        height: "60px",
                        width: "200px",
                        fontSize: "20px",
                        justifyContent: "flex-start",
                        display: "flex",
                        marginTop: "50px",
                      }}
                    >
                      And More ...
                    </Button>
                  </div>
                </div>
              </Grid>
            ))}

            {HealthCare.map((item: ServiceProps) => (
              <Grid item xs={6} key={item.id}>
                <div style={{ marginLeft: "120px",marginTop:"-300px" }}>
                  <img
                    src={item.image}
                    width="600px"
                    height="auto"
                    alt="/"
                    style={{
                      justifyContent: "flex-start",
                      display: "flex",
                      minWidth: "100px",
                      width: "100%",
                      maxWidth: "600px",
                    }}
                  />
                  <h2
                    style={{
                      justifyContent: "flex-start",
                      display: "flex",
                      fontFamily: "Clash Display",fontSize:"30px"
                    }}
                  >
                    {item.title}
                  </h2>
                  <p
                    style={{
                      textAlign: "left",
                      fontFamily: "Calibre",
                      width: "600px",fontSize:"17px"
                    }}
                  >
                    {item.details}
                  </p>
                </div>
              </Grid>
            ))}

            <Grid item xs={8}></Grid>
          </Grid>
        </Box>
      </ServiceGrid>
      <ServiceMobile />
    </div>
  );
}

export default Service;
