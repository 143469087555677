import React from "react";
import { LogoImage, NavbarLi, NavbarUl, NavbarWrapper } from "./Navbar.styles";
import logo from "../../images/Asset/Layer 2.png";
import HamburgerMenu from "./HamburgerMenu";

export const NavbarLiData = [
  { id: 1, name: "Home", link: "/" },
  { id: 2, name: "About", link: "/about" },
  { id: 3, name: "Services", link: "/service" },
  { id: 4, name: "Team", link: "/team" },
  { id: 5, name: "Contact", link: "/contact" },
];
function Navbar() {
  const [colorChange, setColorChange] = React.useState("false");

  const changeNavbarColor = () => {
    if (window.scrollY >= 20) {
      setColorChange("true");
    } else {
      setColorChange("false");
    }
  };

  window.addEventListener("scroll", changeNavbarColor);

  return (
    <NavbarWrapper colored={colorChange}>
      <a href="/">
        <LogoImage src={logo} width={200} colored={colorChange} />
      </a>

      <NavbarUl colored={colorChange}>
        {NavbarLiData.map((item) => (
          <NavbarLi
            smooth={true}
            to={item.link}
            key={item.id}
            colored={colorChange}
          >
            {item.name}
          </NavbarLi>
        ))}
      </NavbarUl>

      <HamburgerMenu />
    </NavbarWrapper>
  );
}

export default Navbar;
