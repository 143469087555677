import { Container } from "@mui/joy";
import {
  AboutBox,
  AboutCardTitle,
  AboutDataWrapper,
  AboutDataWrapperDiv,
  AboutHeaderBox,
  AboutHeaderSubText,
  AboutHeaderText,
  EllipseImage,
} from "./About.style";
import { AboutData } from "./AboutData";
import ellipseImage from "../../CommonComponents/images/Asset/Ellipse 1.png";

interface AboutProps {
  id: number;
  title: string;
  details: string;
  icon: string;
}
function About() {
  const style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (min-width: 300px) and (max-width: 1200px)": {
      flexDirection: "column",
      marginTop: "-80px",
    },
  };
  return (
    <div
      style={{
        background: "#F9F9F9",
        height: "100%",
        paddingBottom: "80px",
        marginTop: "-20px",
        paddingTop: "20px",
      }}
      id="/about"
    >
      <AboutHeaderText>
        <AboutHeaderBox></AboutHeaderBox>Who We Are
      </AboutHeaderText>

      <AboutHeaderSubText>
        We provide reliable resources, solutions focused services and
        time-efficient solutions to help business reach their full potential and
        shape a brighter future.
      </AboutHeaderSubText>

      <div>
        <Container sx={{ ...style }}>
          {AboutData.map((item: AboutProps) => (
            <AboutBox
              key={item.id}
              sx={{ boxShadow: 3, flexDirection: "column" }}
            >
              <div style={{ marginTop: "-100px" }}>
                <img
                  src={item.icon}
                  alt="/"
                  style={{
                    paddingTop: "50px",
                    paddingLeft: "35px",
                    position: "absolute",
                  }}
                />
                <EllipseImage src={ellipseImage} />
              </div>
              <div>
                <>
                  {" "}
                  <AboutCardTitle>{item.title}</AboutCardTitle>
                  <AboutDataWrapperDiv>
                    <AboutDataWrapper>{item.details}</AboutDataWrapper>
                  </AboutDataWrapperDiv>
                </>
              </div>
            </AboutBox>
          ))}
        </Container>
      </div>
    </div>
  );
}

export default About;
