import image1 from "../../CommonComponents/images/Asset/Mask group_4.png"; 
import image2 from "../../CommonComponents/images/Asset/Mask group_2.png";
import image3 from "../../CommonComponents/images/Asset/Mask group_3.png";

const style1 = {
  position: "absolute",
  marginTop: "450px",
  "@media (min-width: 300px) and (max-width: 1200px)": {
    marginTop: "50rem",
    position: "relative",
  },
};
export const style2 = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (min-width: 300px) and (max-width: 1200px)": {
    flexDirection: "column",
    marginTop: "5px",
  },
};

export const Business = [
  {
    id: 1,
    title: "Business Consulting and Coaching ",
    image: image1,
    style: style1,
    details: `Our team of experienced business consultants and coaches provide customized 
services that help leaders and entrepreneurs achieve their objectives and drive
their organization to success. we work closely with our clients to fully understand 
their unique business needs, challenges, and objectives, and provide tailored 
solutions. our consulting and coaching services include strategy development, 
leadership coaching, team building, performance management, business planning, and systems development. we provide a holistic approach to business consulting and coaching, that aims to address all the kay aspects of running a business, helping our clients to achieve to achieve their desired outcomes and reach their full potential.`,
  },
];

export const HealthCare = [
  {
    id: 2,
    title: "Healthcare Billing",
    image: image2,
    style: style1,
    details: `Our healthcare billing services are designed to help healthcare providers and facilities get 
      reimbursement for their services quickly and accurately. we understand the constantly
      changing healthcare reimbursement landscape and can navigate it to make sure our 
      clients are  reimbursed for the services they provide. Our team of experts will work closely
      with you to understand your specific billing needs and provide tailored solutions that 
      optimize your reimbursement process. with out help. you can focus on providing quality 
      care to your patients, while we handle the financial side of the business.`,
  },
];

export const CRM = [
  {
    id: 3,
    title: "CRM and EHR Integration",
    image: image3,
    style: style1,
    details: `Our team of experts specialize in integrating Crm and EHR systems to provide 
      businesses with a more streamlined and efficient client management experience. 
      by integrating these systems, we are able to automate administrative tasks, reduce 
      data entry errors, and provide real-time access to customer information. additionally,
      integrating CRM and/or EHR Systems allows  for improved communication and 
      collaboration across departments and teams.`,
  },
];
