import { Container } from "@mui/joy";
import { Box } from "@mui/material";
import styled from "styled-components";

export const AboutHeaderText = styled.h2`
letter-spacing:2px;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
  display: flex;
  padding-top: 20px;
  padding-left: 5.5%;
  position: relative;
  flex-direction: column;

  font-family: "Clash Display";

  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 74px;

  color: #050505;
  z-index: 0;
  @media (min-width: 300px) and (max-width: 700px) {
    justify-content: center;
    letter-spacing:0px;
    align-items: center;
    text-align: center;
    width: 386px;
    font-weight: 600;
    font-size: 40px;
    z-index: 0;
  }
`;
export const AboutHeaderBox = styled.div`
  background: #f3c539;
  
  height: 20px;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;

  @media (min-width: 1800px) and (max-width: 7000px) {
    position: absolute;
    top: 60px;
    z-index: -1;
    height: 16px;
    width: 400px;
  }

  @media (min-width: 1250px) and (max-width: 1800px) {
    position: absolute;
    top: 60px;
    z-index: -1;
    height: 16px;
    width: 400px;
  }

  @media (min-width: 750px) and (max-width: 1200px) {
    position: absolute;
    top: 55px;
    z-index: -1;
    width: 380px;
  }
  @media (min-width: 300px) and (max-width: 700px) {
    position: absolute;
    top: 55px;
    z-index: -1;
    height: 16px;
    width: 250px;
  }
`;

export const AboutHeaderSubText = styled.p`
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
  display: flex;
  padding-top: 0%;
  position: relative;
  padding-left: 5.5%;

  font-family: "Calibre";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  width: 60rem;

  text-align: start;

  color: #050505;
  margin-bottom: 10rem;

  @media (min-width: 300px) and (max-width: 1200px) {
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    width: 90%;
    font-size: 20px;
    line-height: 24px;
    padding-top: 0%;
  }
  @media (min-width: 300px) and (max-width: 700px) {
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    width: 90%;
    font-size: 20px;
    line-height: 24px;
    padding-top: 0%;
  }
`;

export const AboutBox = styled(Box)`
  box-sizing: border-box;
  width: 500px;
  height: 400.7px;
  background: #ffffff;
  border: 1px solid #f7f7f7;
  border-radius: 28px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  margin: 1rem;
  padding: 20px;
  @media (min-width: 300px) and (max-width: 1200px) {
    margin-top: 50px;
    width: 90%;
  }
`;
export const EllipseImage = styled.img`
  margin-bottom: 0px;
  padding-top: 20px;
`;
export const AboutDataWrapper = styled(Container)`
  justify-content: center;
  align-items: center;
  display: flex;
  
  font-family: "Calibre";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
 

  color: #000000;

  border: 1px solid #f7f7f7;

  @media (min-width: 300px) and (max-width: 700px) {
    flex-direction: column;
  }
`;
export const AboutCardTitle = styled.h3`
  letter-spacing:2px;
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 26px;
  text-align: center;
  line-height: 26px;
  color: #000000;
  border: 1px solid #f7f7f7;
`;
export const AboutDataWrapperDiv = styled.div`
width: 400px;

@media (min-width: 300px) and (max-width: 1280px) {
    width: 100%;
}

`