import React from "react";
import { ContactWrapper } from "./Contact.style";
import Grid from "@mui/material/Grid";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { GrSend } from "react-icons/gr";
import emailjs from "@emailjs/browser";
import { Form } from "./Contact.style";

import { Modal, ModalClose, Sheet } from "@mui/joy";

function Contact(props) {
  const [notification, setNotification] = React.useState("");

  const form = React.useRef();


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_7yergli",
        "template_bqumxfo",
        form.current,
        "23Td6gBYrRhqGPtjx"
      )
      .then(
        (result) => {
          console.log(result.text);
          window.location.reload();
          setNotification("Message has been Sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={props.open}
      onClose={props.handleClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Sheet
        sx={{
          maxWidth: 600,
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
          height: "500px",
        }}
      >
        <ModalClose
          variant="outlined"
          sx={{
            top: "calc(-1/4 * var(--IconButton-size))",
            right: "calc(-1/4 * var(--IconButton-size))",
            boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
            borderRadius: "50%",
            bgcolor: "background.body",
          }}
        />

        <ContactWrapper id="/contact">
          <Form ref={form} onSubmit={sendEmail}>
            <Card sx={{ justifyContent: "center", display: "flex" }}>
              <Box sx={{ width: "100%" }}>
                <h3
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    fontFamily: "Arima Madurai, cursive",
                  }}
                >
                  CONTACT US
                </h3>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ padding: "50px" }}
                >
                  <Grid item xs={6}>
                    <TextField
                      required
                      type="name"
                      name="first_name"
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      type="name"
                      name="last_name"
                      id="outlined-basic"
                      label="Last Name"
                      variant="outlined"
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-basic"
                      type="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-basic"
                      type="phone"
                      name="phone"
                      label="Phone"
                      variant="outlined"
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      name="message"
                      sx={{ width: "100%" }}
                      id="outlined-multiline-static"
                      label="Message"
                      multiline
                      rows={4}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={12} type="submit" value="Send">
                    <Button
                      sx={{
                        width: "100%",
                        justifyContent: "center",
                        display: "flex",
                        backgroundColor: "rgba(179, 183, 187, 1)",
                        color: "#ffffff",
                      }}
                      variant="contained"
                      type="submit"
                      value="Send"
                    >
                      Send{" "}
                      <GrSend
                        style={{ fontSize: "20px", paddingLeft: "10px" }}
                      />
                    </Button>
                  </Grid>
                  <h3>{notification}</h3>
                </Grid>
              </Box>
            </Card>
          </Form>
        </ContactWrapper>
      </Sheet>
    </Modal>
  );
}

export default Contact;
