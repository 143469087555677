import {
  BackgroundImageWrapper,
  HomePageText,
  HomeTextWrapper,
  HomeWrapper,
} from "./Home.style";

import image from "../../CommonComponents/images/Asset/Mask group.png";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Clients from "./Clients";
import { useRecoilState } from "recoil";
import { ScheduleModal } from "../../CommonComponents/GlobalStateManagement";
import Schedule from "./Schedule";
function Home() {
  const [isScheduleModalOpen, setIsScheduleModalOpen] =
    useRecoilState(ScheduleModal);

  const handleOpen = () => {
    setIsScheduleModalOpen(true);
    console.log(isScheduleModalOpen);
  };
  return (
    <>
      <HomeWrapper id="/" style={{ backgroundImage: `url('${image}')` }}>
        <BackgroundImageWrapper style={{ backgroundImage: `url(${image})` }}>
          <HomeTextWrapper style={{ backgroundImage: `url(${image})` }}>
            {" "}
            <HomePageText>
              We Offer{" "}
              <span style={{ fontWeight: "bolder" }}>Customized Solutions</span>{" "}
              for sustainable and responsible growth{" "}
            </HomePageText>
            <div style={{ justifyContent: "flex-start", display: "flex" }}>
              <Button
                onClick={handleOpen}
                variant="contained"
                sx={{
                  height: "50px",
                  backgroundColor: "#f3c539",
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Schedule a free Consultation
              </Button>
            </div>
            <div
              style={{
                justifyContent: "flex-end",
                display: "flex",
                fontSize: "20px",
                paddingTop: "100px",
                paddingBottom:"100px",
                paddingRight: "60px",
                fontFamily: "Clash Display",color:"#fff"
              }}
            >
              Think
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  border: "solid 1px  ",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
              Connect
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  border: "solid 1px  ",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
              Resolve
            </div>
          </HomeTextWrapper>
        </BackgroundImageWrapper>
      </HomeWrapper>
      <Clients />
      <Schedule />
    </>
  );
}

export default Home;
