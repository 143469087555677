import styled from "styled-components";


export const HomeWrapper = styled.div`
  height: 100%;
  background: no-repeat center center;
  background-size: cover;
  margin-top: -80px;
`;
export const BackgroundImageWrapper = styled.div`
  width: 100%;
  @media (min-width: 300px) and (max-width: 1280px) {
    width: 100%;
  }
`;
export const BackgroundImage = styled.div`
  width: 100%;
  background: #000;

  color: white;
  letter-spacing: 5px;
  font-size: 3rem;
  font-family: "Calibre";
  opacity: 0.3;

  @media (min-width: 300px) and (max-width: 1280px) {
    width: 100%;
  }
`;
export const HomeTextWrapper = styled.div`
  padding-left: 6%;
  position: relative;
  padding-top:20%;
  @media (min-width: 300px) and (max-width: 1280px) {
    padding-top:20%;
  }
`;
export const HomePageText = styled.h2`
  width: 60rem;
  text-align: start;
  font-family: "Calibre";
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 96px;

  color: #ffffff;

  transition: all 1s ease-in-out;
  @media (min-width: 300px) and (max-width: 1000px) {
    margin-top: 200px;
    font-size: 20px;
    width: 100%;
    line-height: 46px;
    transition: all 1s ease-in-out;
  }
`;

export const ScheduleButton = styled.button`
  position: absolute;
  width: 330px;
  height: 61px;
  left: 114px;
  top: 696px;

  background: #f3c539;
  border-radius: 6px;
`;
export const ClientImageWrapper = styled.div`
  /* margin:60px; */
  @media (min-width: 240px) and (max-width: 1000) {
    transition: all 1s ease-in-out;
    margin: 20px;
    overflow: hidden;
  }
`;
export const ClientImage = styled.img`
  transition: all 1s ease-in-out;

  @media (min-width: 300px) and (max-width: 1000px) {
    width: 60px;
    display: flex;
    transition: all 1s ease-in-out;
  }
`;

export const ScheduleFrame = styled.iframe`
  width: 1000px;
  height: 650px;
  overflow: hidden;

  @media (min-width: 300px) and (max-width: 1280px) {
    width: 400px;
    height: 600px;
    overflow: none;
  }
`;
