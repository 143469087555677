import React from "react";
import {
  CardTitle,
  CardWrapper,
  TeamHeaderText,
  TeamWrapper,
} from "./Team.style";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { TeamData } from "./TeamData";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "100px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  borderRadius: "18px",
}));

interface TeamProps {
  id: number;
  image: string;
  title: string;
}
function Team() {
  return (
    <TeamWrapper id="/team">
      <TeamHeaderText>Our Team of Experts</TeamHeaderText>

      <Box
        sx={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          marginTop: "50px",
          paddingBottom: "100px",
        }}
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          style={{ width: "900px" }}
        >
          {TeamData.map((item: TeamProps) => (
            <Grid item lg={6} sm={12} key={item.id}>
              <CardWrapper>
                <Item>
                  <div style={{ paddingRight: "60px" }}>
                    <CardTitle>{item.title}</CardTitle>
                  </div>

                  <img src={item.image} alt="/" />
                </Item>
              </CardWrapper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </TeamWrapper>
  );
}

export default Team;
