import { HumburgerButton } from "../Navbar/Navbar.styles";
import Hamburger from "hamburger-react";
import { MdMiscellaneousServices } from "react-icons/md";

import {
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  DraftsOutlined,
  HomeOutlined,
  PeopleAltRounded,
  InfoRounded,
} from "@material-ui/icons";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { darkMode, testimonialMobileModal } from "../../GlobalStateManagement";
import { Link } from "react-scroll";

const data = [
  {
    name: "Home",
    icon: <HomeOutlined />,
    to: "/",
  },
  { name: "About", icon: <InfoRounded />, to: "/about" },
  { name: "Services", icon: <MdMiscellaneousServices />, to: "/service" },
  { name: "Team", icon: <PeopleAltRounded />, to: "/team" },
  { name: "Contact", icon: <DraftsOutlined />, to: "/contact-us" },
];
function HamburgerMenu(props: any) {
  const [open, setOpen] = useState(false);
  const [isMobileModalOpen, setIsMobileModalOpen] = useRecoilState(
    testimonialMobileModal
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDarkMode, setIsDarkMode] = useRecoilState(darkMode);
  const handleToggleModal = (index: any) => {
    setOpen(false);
    if (index === 4) {
      setIsMobileModalOpen(!isMobileModalOpen);
    }
    console.log("clicked", index);
  };

  const getList = () => (
    <div
      style={{ width: 250, background: "#202020", height: "100%" }}
      onClick={() => setOpen(false)}
    >
      {data.map((item, index) => (
        <Link to={item.to} smooth={true} key={index}>
          <div onClick={() => handleToggleModal(index)}>
            <ListItem button style={{ marginTop: "60px" }}>
              <ListItemIcon style={{ color: "#fff" }}>{item.icon}</ListItemIcon>
              <ListItemText style={{ color: "#fff" }} primary={item.name} />
            </ListItem>
          </div>
        </Link>
      ))}
    </div>
  );
  return (
    <div>
      <HumburgerButton colored={props?.colorChange}>
        <Hamburger
          direction="left"
          toggled={open}
          toggle={() => setOpen(true)}
        />
      </HumburgerButton>
      <Drawer
        open={open}
        anchor={"left"}
        onClose={() => setOpen(false)}
        style={{ transition: "all 1s ease-in-out", height: "500px" }}
      >
        {getList()}
      </Drawer>
    </div>
  );
}

export default HamburgerMenu;
