import { Button } from "@mui/joy";
import React from "react";
import {
  Divider,
  FooterHeaderText,
  FooterHeaderTitles,
  FooterImage,
  FooterText,
  FooterWrapper,
  RightsText,
} from "./Footer.style";
import footerIcon from "../../images/Asset/glenasare15@icloud.com (3).jpg";
import phoneImage from "../../images/Asset/phone.png";
import mailImage from "../../images/Asset/mail.png";
import FooterMobile from "./FooterMobile";
import Contact from "../../../Pages/Contact/Contact";

function Footer() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <FooterWrapper id="/contact">
        <div style={{ paddingTop: "100px" }}>
          <FooterImage src={footerIcon} />
        </div>

        <div
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            display: "flex",
            marginTop: "-100px",
          }}
        >
          <FooterText>
            {" "}
            Our founder understands that true progress can only be made when we
            work together. that’s why at Ohemaa llc, we believe in building
            bridges, not barriers when it come to achieving sustainable and
            responsible success. we believe in fostering collaboration, both
            within the organization and with external partners, to bring about
            positive change.{" "}
          </FooterText>

          <div
            style={{
              paddingLeft: "40rem",
              position: "absolute",
              paddingBottom: "70px",
            }}
          >
            <FooterHeaderTitles>Contact Us</FooterHeaderTitles>

            <FooterHeaderText  >
              <img
                src={phoneImage}
                alt={"/"}
                style={{ paddingRight: "10px" }}
              />{" "}
              480-916-0009
            </FooterHeaderText>
            <FooterHeaderText>
              <img src={mailImage} alt={"/"} style={{ paddingRight: "10px" }} />{" "}
              info@ohemaallc.com
            </FooterHeaderText>
            <div
              style={{
                justifyContent: "flex-end",
                display: "flex",
                paddingRight: "20px",
                paddingTop: "20px",
                cursor: "pointer",
              }}
              onClick={handleOpen}
            >
              <Button
                variant="solid"
                style={{
                  background: "#FF6E00",
                  width: "auto",
                  height: "auto",
                  fontSize: "20px",
                  fontFamily: "Clash Display",
                  fontWeight: "500",
                  lineHeight: "25px",
                  color: "#fff",
                }}
              >
                {" "}
                Send us a Message
              </Button>
            </div>
          </div>

          <div
            style={{
              paddingLeft: "30rem",
              paddingBottom: "100px",
            }}
          >
            <FooterHeaderTitles >Working Hours</FooterHeaderTitles>
            <span>
              <FooterHeaderText >Monday - Friday: 9am - 6pm</FooterHeaderText>
              <FooterHeaderText>Saturday: Closed</FooterHeaderText>
              <FooterHeaderText>Sunday: Closed</FooterHeaderText>
            </span>
          </div>
        </div>

        <Divider />
        <RightsText>
          Copyright © 2023 Ohemaa LLC - All Right Reserved
        </RightsText>
      </FooterWrapper>
      <FooterMobile />
      <Contact handleClose={handleClose} open={open} />
    </>
  );
}

export default Footer;
