import styled from "styled-components";
import Grid from "@mui/material/Grid";

export const ServiceGrid = styled.div`
  text-align: center;
  display: flex;
  position: relative;
 

  width: 100%;

  @media (min-width: 300px) and (max-width: 1380px) {
    display: none;
  }
`;
export const TopLeftGrid = styled.div``;
export const MiddleRightGrid = styled.div``;
export const BottomLeftGrid = styled.div``;

export const MainGridWrapper = styled(Grid)`
  display: flex;

  @media (min-width: 300px) and (max-width: 180px) {
    flex-direction: column;
  }
`;
export const ServiceMobileWrapper = styled.div`
  display: none;

  @media (min-width: 300px) and (max-width: 1380px) {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;
export const ServiceHeaderBox = styled.div`
  background: #f3c539;
  
  height: 20px;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;

  @media (min-width: 1800px) and (max-width: 7000px) {
    position: absolute;
    top: 60px;
    z-index: -1;
    height: 16px;
    width: 600px;
  }

  @media (min-width: 1250px) and (max-width: 1800px) {
    position: absolute;
    top: 60px;
    z-index: -1;
    height: 16px;
    width: 600px;
  }

  @media (min-width: 750px) and (max-width: 1200px) {
    position: absolute;
    top: 55px;
    z-index: -1;
    width: 370px;
  }
  @media (min-width: 300px) and (max-width: 700px) {
    position: absolute;
    top: 55px;
    z-index: -1;
    height: 16px;
    width: 370px;
  }
`