
import { RecoilRoot } from "recoil";
import RouterPages from "./RouterPages";

function App() {
  return (
    <div>
      <RecoilRoot>
        <RouterPages />
      </RecoilRoot>
    </div>
  );
}

export default App;
