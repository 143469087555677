import { atom } from 'recoil';

export const darkMode = atom({
    key: 'isOn',
    default: false,
  });

  export const testimonialModal = atom({
    key: 'isOpen',
    default: false,
  });
  export const testimonialMobileModal = atom({
    key: 'isModalOpen',
    default: false,
  });

  export const LoginVerification = atom({
    key: 'isLogin',
    default: false,
  });

  export const ServiceModal = atom({
    key: 'isServiceModalOpen',
    default: false,
  });
  export const ScheduleModal = atom({
    key: 'isScheduleModalOpen',
    default: false,
  });