import { Button, Container } from "@mui/joy";
import {
  Divider,
  FooterHeaderText,
  FooterHeaderTitles,
  FooterImage,
  FooterMobileWrapper,
  FooterText,
  RightsText,
} from "./Footer.style";
import footerIcon from "../../images/Asset/glenasare15@icloud.com (3).jpg";
import phoneImage from "../../images/Asset/phone.png";
import mailImage from "../../images/Asset/mail.png";
import Contact from "../../../Pages/Contact/Contact";
import React from "react";

function FooterMobile() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div id="/contact">
      <FooterMobileWrapper id="/contact-us">
        <Container>
          <div style={{ paddingTop: "50px" }}>
            <FooterImage src={footerIcon} />
          </div>
          <div>
            <FooterText style={{ width: "100%", marginTop: "-50px",textAlign:"center" }}>
              {" "}
              Our founder understands that true progress can only be made when
              we work together. that’s why at Ohemaa llc, we believe in building
              bridges, not barriers when it come to achieving sustainable and
              responsible success. we believe in fostering collaboration, both
              within the organization and with external partners, to bring about
              positive change.{" "}
            </FooterText>
          </div>
          <div
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div style={{}}>
              <FooterHeaderTitles>Contact Us</FooterHeaderTitles>

              <FooterHeaderText>
                <img
                  src={phoneImage}
                  alt={"/"}
                  style={{ paddingRight: "10px" }}
                />{" "}
                
                480-916-0009
              </FooterHeaderText>
              <FooterHeaderText>
                <img
                  src={mailImage}
                  alt={"/"}
                  style={{ paddingRight: "10px" }}
                />{" "}
                info@ohemaallc.com
              </FooterHeaderText>
              <div
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                  paddingRight: "20px",
                  paddingTop: "20px",
                  cursor: "pointer",
                }}
                onClick={handleOpen}
              >
                <Button
                  variant="solid"
                  style={{
                    background: "#FF6E00",
                    width: "auto",
                    height: "auto",
                    fontSize: "20px",
                    fontFamily: "Clash Display",
                    fontWeight: "500",
                    lineHeight: "25px",
                    color: "#fff",
                  }}
                >
                  {" "}
                  Send us a Message
                </Button>
              </div>
            </div>

            <div
              style={{
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "40px",
              }}
            >
              <FooterHeaderTitles>Working Hours</FooterHeaderTitles>

              <FooterHeaderText>Monday - Friday: 9am - 6pm</FooterHeaderText>
              <FooterHeaderText>Saturday: Closed</FooterHeaderText>
              <FooterHeaderText>Sunday: Closed</FooterHeaderText>
            </div>
          </div>

         
          <Divider style={{ width: "100%" }} />
          <RightsText>Copyright © 2023 Ohemaa LLC - All Right Reserved</RightsText>
        </Container>
      </FooterMobileWrapper>
      <Contact handleClose={handleClose} open={open} />
    </div>
  );
}

export default FooterMobile;
