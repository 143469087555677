import styled from "styled-components";

export const TeamWrapper = styled.div`
  height: auto;
  width: 100%;
  background: #f3c539;
`;
export const TeamHeaderText = styled.h1`
letter-spacing:2px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  padding-top: 5%;

  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 74px;
  color: #ac6925;
`;

export const CardTitle = styled.h3`
  font-family: "Calibre";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  text-align:left;
  width:250px;
`;
export const CardWrapper = styled.div`
  
  @media (min-width: 300px) and (max-width: 1280px) {
    padding: 10px;
  }
`