import styled from "styled-components";

interface isDarkMode {
  isDarkMode: boolean;
}

export const ContactWrapper = styled.div<isDarkMode>`
  height: 100%;
  margin-top: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: all 1s ease-in-out;
  background: transparent;
  filter: ${(props) =>
    props.isDarkMode ? "brightness(70%)" : "brightness(100%)"};

 
  @media (min-width: 300px) and (max-width: 1280px) {
    background: #fff3e3;
    overflow-y: hidden;
    z-index: -1;
  }
`;
export const Form = styled.form`
  width: 100%;
  justify-content: center;
  display: flex;
  padding: 200px 20px 200px 20px;

  @media only screen and (max-width: 600px) {
    width: 100%;
    justify-content: center;
    display: flex;
  }
`;
export const CustomerServImage = styled.img`

@media (min-width: 300px) and (max-width: 1280px) {
   display: none;
  }
`