import { Container } from "@material-ui/core";
import React from "react";
import { ClientData } from "./ClientData";
import { ClientImage, ClientImageWrapper } from "./Home.style";

interface ClientProps {
  id: number;
  image: string;
}
function Clients() {
  return (
    <div
      style={{
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        backgroundColor: "#F3C53929",
        height: "180px",
      }}
    >
      
        {ClientData.map((item: ClientProps) => (
          <ClientImageWrapper key={item.id}>
            <Container><ClientImage src={item.image} alt="client" /></Container>
          </ClientImageWrapper>
        ))}
      
    </div>
  );
}

export default Clients;
