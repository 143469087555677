import styled from "styled-components";
import { Link } from "react-scroll";

interface NavbarMain {
  colored: string;
}

export const NavbarWrapper = styled.div<NavbarMain>`
  height: 8vh;
  width: 100%;
  z-index:99;
  background-color: ${(props) =>
    props.colored === "true" ? "#202020" : "transparent "};
  display: flex;
  justify-content: space-between;
  top: 0%;
  position: fixed;
  z-index: 1;
  align-items: center;
  transition: all 1s ease-in-out;
  @media (min-width: 1300px) and (max-width: 1500px) {
    height: 15vh;
  }

  @media (min-width: 300px) and (max-width: 1280px) {
    height: 10vh;
  }
`;
export const NavbarUl = styled.ul<NavbarMain>`
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => (props.colored === "true" ? "" : "100px")};
  transition: all 1s ease-in-out;
  padding-left: 15rem;
  width: 100%;

  @media (min-width: 300px) and (max-width: 1280px) {
    display: none;
  }
`;
export const NavbarLi = styled(Link)<NavbarMain>`
  color: #FFFFFF;
  margin: 0 4rem;
  font-size: 20px;
  line-height:25px;
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #000;
  }
`;

export const LogoImage = styled.img<NavbarMain>`
  margin-top: ${(props) => (props.colored === "true" ? "" : "100px")};
  transition: all 1s ease-in-out;
  padding-left: 4rem;

  @media (min-width: 300px) and (max-width: 1280px) {
    padding-left: 0rem;
    margin-top: ${(props) => (props.colored === "true" ? "" : "0px")};
  }
`;

export const HumburgerButton = styled.div<NavbarMain>`
  @media (min-width: 500px) {
    display: none;
  }
  @media (min-width: 300px) and (max-width: 1280px) {
    margin-top: ${(props) => (props.colored === "true" ? "" : "0px")};
    display: flex;
    justify-content: flex-end;
    width: 100%;
    top: 0;
  }
`;


export const ContactButtonWrapper = styled.div`
  width: 100%;
  padding-left: 4rem;
`;
