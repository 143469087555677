import React from "react";
import Navbar from "./Components/CommonComponents/common/Navbar/Navbar";
import Home from "./Components/Pages/Home/Home";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import About from "./Components/Pages/About/About";
import Service from "./Components/Pages/Service/Service";
import Team from "./Components/Pages/Team/Team";
import Footer from "./Components/CommonComponents/common/Footer/Footer";
function RouterPages() {
  return (
    <div>
      <Router>
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <Routes>
          <Route path="/" element={<About />} />
        </Routes>
        <Routes>
          <Route path="/" element={<Service />} />
        </Routes>
        <Team />
        <Footer />
      </Router>
    </div>
  );
}

export default RouterPages;
