import React from "react";
import { ServiceMobileWrapper } from "./Serivce.style";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Business, CRM, HealthCare } from "./ServiceData";

export interface ServiceProps {
  id: number;
  title: string;
  details: string;
  image: string;
}
function ServiceMobile() {
  return (
    <ServiceMobileWrapper>
      {" "}
      {HealthCare.map((item: ServiceProps) => (
        <Card sx={{ maxWidth: 345, marginTop: "30px" }} key={item.id}>
          <CardMedia
            sx={{ height: 140 }}
            image={item.image}
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" style={{fontFamily:"Calibre" }}>
              {item.title}
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{fontFamily:"Clash Display" }}>
              {item.details}
            </Typography>
          </CardContent>
          
        </Card>
      ))}
      {CRM.map((item: ServiceProps) => (
        <Card sx={{ maxWidth: 345, marginTop: "30px" }} key={item.id}>
          <CardMedia
            sx={{ height: 140 }}
            image={item.image}
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" style={{fontFamily:"Calibre" }}>
              {item.title}
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{fontFamily:"Clash Display" }}>
              {item.details}
            </Typography>
          </CardContent>
        
        </Card>
      ))}
      {Business.map((item: ServiceProps) => (
        <Card sx={{ maxWidth: 345, marginTop: "30px" }} key={item.id}>
          <CardMedia
            sx={{ height: 140 }}
            image={item.image}
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" style={{fontFamily:"Calibre" }}>
              {item.title}
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{fontFamily:"Clash Display" }}>
              {item.details}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small">And More ...</Button>
          </CardActions>
        </Card>
      ))}
    </ServiceMobileWrapper>
  );
}

export default ServiceMobile;
